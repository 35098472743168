<template>
    <v-card
    class="mx-auto my-2"
  >
    <!-- <v-img
      class="white--text align-end"
      height="200px"
      src="https://cdn.vuetifyjs.com/images/cards/docks.jpg"
    >
      <v-card-title>Top 10 Australian beaches</v-card-title>
    </v-img> -->

    <v-card-title>
      {{ element.title }}
    </v-card-title>

    <v-card-subtitle class="pb-0" v-if="!utils.checkIsUndefinedOrEmpty(element.subtitle)">
     {{ element.subtitle }}
    </v-card-subtitle>

    <!-- <v-card-text class="text--primary">
      <div>Whitehaven Beach</div>

      <div>Whitsunday Island, Whitsunday Islands</div>
    </v-card-text> -->

    <v-card-actions>
      <v-btn
        color="secondary"
        text
        @click="editTab"
      >
        Modifica
      </v-btn>

      <v-btn
        color="error"
        text
        @click="deleteTab"
      >
        Elimina
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import swal from '../utils/swal'
import utils from '../utils/utils'
export default {
    name: "ElementCard",
    props: {
        element: Object
    },
    data() {
      return {
        utils
      }
    },
    methods: {
      editTab() {
        this.$router.push({name: "DetailTab", params: {id: this.element.id}})
      },
      deleteTab() {
        swal.deleteElementSwal(() => {
          this.$store.commit("deleteTab", this.element)
        })
      }
    }
}
</script>