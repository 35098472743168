<template>
  <div class="home h-100">
    <v-alert
      border="top"
      class="mt-4 w-100"
      colored-border
      type="info"
      elevation="2"
      v-if="categories.length === 0"
    >
      Nessun categoria presente, creane uno nuovo attraverso il bottone centrale in basso.
    </v-alert>
    <div v-else>
        <v-tabs
          v-model="tab"
        >
          <v-tabs-slider color="primary"></v-tabs-slider>

          <v-tab
            v-for="item in categories"
            :key="item.id"
          >
            {{ item.title }}
          </v-tab>
        </v-tabs>

    <v-tabs-items v-model="tab"
        class="h-100">
      <v-tab-item
        v-for="item in categories"
        :key="item.id"
        class="h-100"
      >
        <v-row class="ma-0">
          <v-alert
            border="top"
        class="mt-4 w-100"
            colored-border
            type="info"
            elevation="2"
            v-if="item.elements.length === 0"
          >
            Nessuna tab per questa categoria, creane una nuova attraverso il bottone centrale in basso.
          </v-alert>
          <v-col v-else class="pt-0" cols="12" md="3" sm="6" v-for="element in item.elements" :key="element.id">
            <element-card :element="element" />
          </v-col>
          <div class="text-center flex-row-all">
            <v-btn
              color="error"
              text
              @click="deleteCategory"
            >
              Elimina categoria
            </v-btn>
          </div>
        </v-row>
      </v-tab-item>
    </v-tabs-items>
    </div>
  </div>
</template>

<script>
import ElementCard from "../components/ElementCard.vue"
import { mapGetters } from 'vuex'
import swal from '../utils/swal'

export default {
  name: 'Home',
  data () {
      return {
        tab: null,
        
      }
    },
    watch: {
      tab: {
        deep: true,
        handler: function() {
          this.$store.commit("setTabIndex", this.tab)
        }
      }
    },
    methods: {
      deleteCategory() {
        swal.deleteElementSwal(() => {
          this.$store.commit("deleteTabIndex")
          this.tab = 0
        })
      }
    },
    computed: {
      ...mapGetters(["categories"])
    },
  components: {
    ElementCard
  }
}
</script>
